<template>
  <div
    :class="{'-translate-x-full' : !open}"
    class="fixed left-0 top-0 h-full w-52 bg-primary px-6 py-12 text-lg text-white transition"
  >
    <button
      class="-ml-3 mb-16 size-7 text-white sm:hidden"
      @click="open = !open"
    >
      <UiIcon
        name="XMark"
        size="extra-small"
      />
    </button>
    <nav class="mb-12">
      <ul
        role="list"
        class="space-y-2"
      >
        <template
          v-for="item in menu"
        >
          <li
            v-if="item.if ? item.if() : true"
            :key="item.label"
          >
            <AppMenuMobileItem
              :label="item.label"
              :to="item.to"
            />
          </li>
        </template>
      </ul>
    </nav>
    <button
      class="text-primary-300"
      @click="signOut"
    >
      Uitloggen
    </button>
  </div>
</template>

<script lang="ts" setup>
import type {RouteLocationRaw} from 'vue-router';

const route = useRoute();

const open = defineModel<boolean>('open');

defineProps<{
  menu: Array<{
    label: string,
    to: RouteLocationRaw
  }>
}>();

const {signOut} = useSession();

watch(() => route.fullPath, () => open.value = false);
</script>
